<template>
  <div class="vz-request-document-form">
    <vz-switcher
      v-model="modelValue.mode"
      :items="docTypes"
      :use-status="false"
      title="Типы документов"
      big
      @change="onChangeMode"
    >
      <template #label>
        <label class="vz-input-label public-style">Типы документов</label>
      </template>
    </vz-switcher>
    <template v-if="isCargoType">
      <vz-input
        v-model.trim="modelValue.orderNumber"
        required
        :placeholder="orderNumberPlaceholder"
        :rules="[formRules.required]"
        big
        label="Номер заказа"
        label-public-style
        class="vz-focus-on-next"
        @change="onNumberChange"
        @keydown.enter="focusOnNext"
      />
      <vz-phone-input
        big
        v-model="modelValue.phone"
        label="Телефон из заказа"
        :rules="[formRules.required, formRules.phone]"
        label-public-style
        class="vz-focus-on-next"
        @keydown.enter="focusOnNext"
      />
      <div>
        <label class="vz-input-label required public-style">Документы</label>
        <div class="vz-checkbox-block">
          <vz-checkbox
            v-model="updCheckbox"
            :disabled="disabledUPDCheckbox"
            label="УПД"
            @change="onChangeUPDCheckbox"
          />
          <vz-checkbox
            v-model="accountCheckbox"
            :disabled="disabledAccountCheckbox"
            label="Счёт"
            @change="onChangeAccountCheckbox"
          />
          <vz-checkbox
            v-model="erCheckbox"
            :disabled="disabledERCheckbox"
            label="ЭР"
            @change="onChangeERCheckbox"
          />
        </div>
      </div>
    </template>
    <template v-if="isReconciliation">
      <contact-autocomplete
        :search-params="{ entity: 'corporation' }"
        placeholder="Поиск по ИНН"
        label="Компания"
        required
        big
        label-public-style
        :rules="[formRules.required]"
        @change="onCompanyChange"
      />
      <div v-if="hasEdw">
        <label class="vz-input-label public-style">Документы</label>
        <div class="vz-checkbox-block">
          <vz-checkbox
            v-model="modelValue.deliverTo.eWorkflow"
            :disabled="edwDisabled"
            :title="edwDisabled && isCargoType ? 'Только для УПД' : ''"
            class="flex-inline mr-40"
            :small="false"
            :label="isCargoType ? 'ЭДО (только УПД)' : 'ЭДО'"
            @change="onChangeUPDCheckbox"
          />
        </div>
      </div>
      <div>
        <label class="vz-input-label public-style">Однократно за период</label>
        <vz-once v-model="modelValue.period" />
      </div>
    </template>
    <div>
      <vz-email-list
        v-if="!modelValue.deliverTo.eWorkflow"
        v-model="modelValue.deliverTo.email"
        required
        label-public-style
        placeholder="Ваша почта"
      />
      <vz-email-list v-else disabled label-public-style />
    </div>
  </div>
</template>

<script setup lang="ts">
import {storeToRefs} from 'pinia'
import {useContractorStore} from '@/stores/personal/contractors'
import {Contractor, SwitchItem} from '@/types/types'
import VzInput from '~/components/ui-kit/vz-input.vue';
import VzCheckbox from '~/components/ui-kit/vz-checkbox.vue';
import VzSwitcher from '~/components/ui-kit/vz-switcher.vue';
import VzOnce from '~/components/forms/contractor/vz-add-document-form/vz-once.vue';
import ContactAutocomplete from '~/components/autocomplete/contact-autocomplete.vue';
import VzPhoneInput from '~/components/ui-kit/vz-phone-input.vue';
import {PropType} from 'vue';
import {OrderDocumentRequest} from '~/types/api';
import {getContractorGuid} from '~/api/order';
import formRules from '~/common/vz-form-rules';
import {getNextFocusEl, orderNumberPlaceholder} from '~/common/functions';

const props = defineProps({
  modelValue: {
    type: Object as PropType<OrderDocumentRequest>,
    default: () => ({})
  }
})
const {contractor, hasContractorEDO} = storeToRefs(useContractorStore())
const accountCheckbox = ref(false)
const updCheckbox = ref(true)
const erCheckbox = ref(false)
const hasEdw = ref(false)
const docType = ref('cargo')

const isCargoType = computed(() => {
  return props.modelValue.mode === 'cargo'
})

const isReconciliation = computed(() => {
  return props.modelValue.mode === 'reconciliation'
})

const docTypes = computed((): SwitchItem[] => [
  {title: 'По грузу', value: 'cargo'},
  {title: 'Акт сверки', value: 'reconciliation'},
])

const edwDisabled = computed(() => {
  return erCheckbox.value || accountCheckbox.value
})

const isEdw = computed((): boolean => {
  return !!props.modelValue.deliverTo.eWorkflow
})

const disabledAccountCheckbox = computed((): boolean => {
  if (isEdw.value) {
    return true
  }
  return accountCheckbox.value && !erCheckbox.value && !updCheckbox.value
})

const disabledUPDCheckbox = computed((): boolean => {
  return updCheckbox.value && !erCheckbox.value && !accountCheckbox.value
})

const disabledERCheckbox = computed((): boolean => {
  if (isEdw.value) {
    return true
  }
  return erCheckbox.value && !updCheckbox.value && !accountCheckbox.value
})

function onChangeAccountCheckbox(val: string): void {
  val ? addTypeList('invoice') : removeTypeList('invoice')
}

function onChangeUPDCheckbox(val: string): void {
  val ? addTypeList('UPD') : removeTypeList('UPD')
}

function onChangeERCheckbox(val: string): void {
  val ? addTypeList('ER') : removeTypeList('ER')
}

function onChangeDeliveryType(deliveryType: string): void {
  if (deliveryType === 'edw') {
    accountCheckbox.value = false
    updCheckbox.value = true
    erCheckbox.value = false
    props.modelValue.mode = 'cargo'
    props.modelValue.docTypes = ['UPD']
  }
}

function addTypeList(val: any): void {
  props.modelValue.docTypes.push(val)
}

function removeTypeList(val: string): void {
  props.modelValue.docTypes = props.modelValue.docTypes.filter((v: any) => v !== val)
}

async function onCompanyChange(_?: any, obj?: Contractor) {
  if (obj?.inn && props.modelValue) {
    props.modelValue.inn = obj.inn
    props.modelValue.kpp = obj.kpp
  }
  if (props.modelValue.inn) {
    const { eWorkflow } = await getContractorGuid({ inn: props.modelValue.inn, kpp: props.modelValue.kpp })
    hasEdw.value = eWorkflow
    if (!hasEdw.value) {
      props.modelValue.deliverTo.eWorkflow = false
    }
  } else {
    hasEdw.value = false
  }
}

async function onNumberChange() {
  if (!props.modelValue?.orderNumber) {
    hasEdw.value = false
    return
  }

  const { eWorkflow } = await getContractorGuid({ orderNumber: props.modelValue?.orderNumber})
  hasEdw.value = eWorkflow
  if (!hasEdw.value) {
    props.modelValue.deliverTo.eWorkflow = false
  }
}

function onChangeMode() {
  if (isCargoType.value) {
    onNumberChange()
  } else {
    onCompanyChange()
  }
}

function focusOnNext() {
  const allFocusable = document.querySelectorAll('.vz-request-document-form .vz-focus-on-next input');
  getNextFocusEl(allFocusable, document.activeElement)?.focus()
}
</script>

<style lang="scss" scoped>
.vz-request-document-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
</style>