<template>
  <vz-dialog
    ref="dialog"
    class="vz-request-document-modal"
    name="vz-request-document-modal"
    big
    @showed="onToggleForm"
    @hide="onHideDialog"
  >
    <template #title>
      Запрос оригиналов документов
      <vz-tooltip-info :text="tooltipText" size="20" />
    </template>
    <vz-loader :status-loader="loader"/>
    <vz-form
      v-model="isValid"
      ref="vzform"
    >
      <vz-request-document-form
        v-if="isOpenModal"
        v-model="formModel"
      />
    </vz-form>
    <template #footer>
      <vz-button
        variant="primary"
        big
        outline
        icon-name="arrow-left"
        @click="back"
      >
        Назад
      </vz-button>
      <vz-button
        variant="primary"
        big
        :disabled="!isValid"
        @click="onSendForm"
      >
        Запросить
      </vz-button>
    </template>
  </vz-dialog>
</template>

<script setup lang="ts">
import { useDayjs } from '#dayjs'
import {storeToRefs} from 'pinia'
import {deepCopyObject} from '@/common/functions'
import {useTerminalStore} from '@/stores/terminal';
import VzDialog from '~/components/ui-kit/vz-dialog.vue';
import VzRequestDocumentForm from '~/components/forms/contractor/vz-add-document-form/vz-request-document-form.vue';
import VzLoader from '~/components/ui-kit/vz-loader.vue';
import VzForm from '~/components/ui-kit/vz-form.vue';
import VzButton from '~/components/ui-kit/vz-button.vue';
import {OrderDocumentRequest} from '~/types/api';
import {createOrderDocumentRequest} from '~/api/order';
import VzTooltipInfo from '~/components/ui-kit/vz-tooltip-info.vue';

const dayjs = useDayjs()
const {terminalList} = storeToRefs(useTerminalStore())
const vzform = ref()
const {$showToast, $showDialog, $hideDialog} = useNuxtApp()
const tooltipText = 'Вы можете заказать оригиналы документов в Личном кабинете.' +
  ' Для этого необходимо войти в Личный кабинет, выбрать заказ и нажать кнопку "Оригиналы документов".' +
  ' В открывшейся форме Вы можете запросить закрывающие документы как по нескольким грузам, так и за разный период.'

const defaultForm: OrderDocumentRequest = {
  mode: 'cargo',
  orderNumber: '',
  phone: '',
  deliverTo: {
    email: []
  },
  docTypes: ['UPD'],
  period: {
    start: dayjs().subtract(1, 'days').format('YYYY-MM-DD'),
    end: dayjs().format('YYYY-MM-DD')
  },
}

const loader = ref(false)
const isValid = ref(true)
const formModel = ref(defaultForm)
const isOpenModal = ref(false)

function onToggleForm(): void {
  resetForm()
  isOpenModal.value = true
}

async function onSendForm(): Promise<void> {
  const form = deepCopyObject(formModel.value)

  if (form.mode === 'cargo') {
    delete form.period
    delete form.inn
    delete form.kpp
  } else {
    delete form.orderNumber
    form.docTypes = []
    delete form.phone
  }

  if (await vzform.value.validate()) {
    loader.value = true
    const res = await createOrderDocumentRequest(form).finally(() => { loader.value = false })
    if (res?.error) {
      $showToast({
        title: 'Уведомление',
        text: res.error?.opt?.message?.description || res?.error?.message || 'Неизвестная ошибка',
        variant: 'error'
      })
    } else {
      $showToast({
        title: 'Уведомление',
        text: 'Запрос успешно отправлен',
        variant: 'success'
      })
      $hideDialog({name: 'vz-add-document-modal'})
    }
  }
}

function onHideDialog() {
  resetForm()
}

function resetForm() {
  formModel.value = deepCopyObject(defaultForm)
}

function back() {
  $hideDialog({name: 'vz-request-document-modal'})
  $showDialog({name: 'feedback-message'})
}
</script>
